import InputField from "./InputField";

const FormSection = ({ title, fields }) => (
  <>
    <span className="section-header">{title}</span>
    {fields.map(({ label, value, setValue, placeholder, type }) => (

      <InputField
        key={label}
        label={label}
        value={value}
        setValue={setValue}
        placeholder={placeholder}
        type={type || 'text'}
      />
    ))}
  </>
); 

export default FormSection;