import React from "react";
import { Routes, Route } from "react-router-dom";
import ClipsContent from "./pages/ClipsContent";
import StoriesContent from "./pages/StoriesContent";
import "./App.css";

const App = () => {
  return (
    <Routes>
      <Route path="/clips" element={<ClipsContent />} />
      <Route path="/stories" element={<StoriesContent />} />
      <Route path="/" element={<ClipsContent />} />
    </Routes>
  );
};

export default App;
