import React, { useState } from "react";
import { formatAsDate } from "../utils/formatAsDate";
import { getTimeDifference } from "../utils/getTimeDifference";

const StoryCard = ({ story, style }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Sort categories alphabetically by externalId
  const sortedCategories = [...story.categories].sort((a, b) =>
    a.externalId.localeCompare(b.externalId)
  );

  return (
    <div className="storyCard" style={style}>
      <a
        href={`https://nba.usestoryteller.com/stories/${story.id}/edit`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="storyImageContainer">
          <img
            className="storyImage"
            src={story.profilePictureUrl}
            alt={story.titles.shortDisplay}
          />
          {story.isLive && (
            <div className="liveIndicator">
              LIVE
            </div>
          )}
        </div>
      </a>

      <a
        href={`https://nba.usestoryteller.com/stories/${story.id}/edit`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="storyTitle">{story.titles.shortDisplay}</div>
      </a>

      <div className="storyContent">
        <div className="infoBox">
          <div className="infoBoxHeader">Time Since Modification</div>
          <div>
            <span>{formatAsDate(story.lastModificationTime)}</span>
            <span> • </span>
            <span>{getTimeDifference(story.lastModificationTime)}</span>
          </div>
        </div>

        <a
          href={`https://nba.usestoryteller.com/stories/${story.id}/edit`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="infoBox">
            <div className="infoBoxHeader">Long Display Title</div>
            <span>{story.titles.longDisplay}</span>
          </div>
        </a>

        <a
          href={`https://nba.usestoryteller.com/stories/${story.id}/edit`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="infoBox">
            <div className="infoBoxHeader">Internal Title</div>
            <span>{story.titles.internal}</span>
          </div>
        </a>

        <div className="infoBox">
          <div className="infoBoxHeader">Score</div>
          <span>{story.score}</span>
        </div>

        <div className="infoBox">
          <div className="infoBoxHeader">Categories</div>
          <div
            className={`categoriesContainer ${isExpanded ? "expanded" : ""}`}
          >
            <div className="categoriesWrapper">
              {sortedCategories.map((category, i) => (
                <a
                  href={`https://nba.usestoryteller.com/schedule/category/${category.externalId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span key={i} className="categoryItem">
                    {category.externalId}
                  </span>
                </a>
              ))}
            </div>
            {sortedCategories.length > 8 && (
              <div
                className="expandButton"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? "Show Less" : "Show More"}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const StoriesGrid = ({ stories }) => {
  return (
    <div className="stories-grid">
      {stories &&
        stories.map((story) => <StoryCard key={story.id} story={story} />)}
    </div>
  );
};

export default StoriesGrid;
