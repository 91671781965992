import { useState } from "react";

const useBoostConfig = (initialValues = {}) => {
  const [creationTimeBoostFactor, setCreationTimeBoostFactor] = useState(initialValues.creationTimeBoostFactor || "50");
  const [likeCountBoostFactor, setLikeCountBoostFactor] = useState(initialValues.likeCountBoostFactor || "0.0001");
  const [shareCountBoostFactor, setShareCountBoostFactor] = useState(initialValues.shareCountBoostFactor || "0.0002");
  const [primaryCategoryBoostFactor, setPrimaryCategoryBoostFactor] = useState(initialValues.primaryCategoryBoostFactor || "10");
  const [secondaryCategoryBoostFactor, setSecondaryCategoryBoostFactor] = useState(initialValues.secondaryCategoryBoostFactor || "5");
  const [clipCreationTimeLowerBound, setClipCreationTimeLowerBound] = useState(initialValues.clipCreationTimeLowerBound || "");
  const [editorialBoostFactor, setEditorialBoostFactor] = useState(initialValues.editorialBoostFactor || "5000");
  const [editorialBoostCategoryExternalId, setEditorialBoostCategoryExternalId] = useState(initialValues.editorialBoostCategoryExternalId || "");
  const [customBoostFactor, setCustomBoostFactor] = useState(initialValues.customBoostFactor || "5");
  const [customBoostCategoryExternalIds, setCustomBoostCategoryExternalIds] = useState(initialValues.customBoostCategoryExternalIds || "");
  const [categoriesToSuppressExternalIds, setCategoriesToSuppressExternalIds] = useState(initialValues.categoriesToSuppressExternalIds || "");
  const [categoriesToSuppressBoostFactor, setCategoriesToSuppressBoostFactor] = useState(initialValues.categoriesToSuppressBoostFactor || "");

  const getBoostConfigString = () => 
    `&creationTimeBoostFactor=${creationTimeBoostFactor}&likeCountBoostFactor=${likeCountBoostFactor}&shareCountBoostFactor=${shareCountBoostFactor}&primaryCategoryBoostFactor=${primaryCategoryBoostFactor}&secondaryCategoryBoostFactor=${secondaryCategoryBoostFactor}&clipCreationTimeLowerBound=${clipCreationTimeLowerBound}&editorialBoostFactor=${editorialBoostFactor}&editorialBoostCategoryExternalId=${editorialBoostCategoryExternalId}&customBoostFactor=${customBoostFactor}&customBoostCategoryExternalIds=${customBoostCategoryExternalIds}&categoriesToSuppressExternalIds=${categoriesToSuppressExternalIds}&categoriesToSuppressBoostFactor=${categoriesToSuppressBoostFactor}`;

  return {
    creationTimeBoostFactor,
    setCreationTimeBoostFactor,
    likeCountBoostFactor,
    setLikeCountBoostFactor,
    shareCountBoostFactor,
    setShareCountBoostFactor,
    primaryCategoryBoostFactor,
    setPrimaryCategoryBoostFactor,
    secondaryCategoryBoostFactor,
    setSecondaryCategoryBoostFactor,
    clipCreationTimeLowerBound,
    setClipCreationTimeLowerBound,
    editorialBoostFactor,
    setEditorialBoostFactor,
    editorialBoostCategoryExternalId,
    setEditorialBoostCategoryExternalId,
    customBoostFactor,
    setCustomBoostFactor,
    customBoostCategoryExternalIds,
    setCustomBoostCategoryExternalIds,
    categoriesToSuppressExternalIds,
    setCategoriesToSuppressExternalIds,
    categoriesToSuppressBoostFactor,
    setCategoriesToSuppressBoostFactor,
    getBoostConfigString
  };
}; 

export default useBoostConfig;