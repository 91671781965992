import { formatAsDate } from "../utils/formatAsDate";
import { getTimeDifference } from "../utils/getTimeDifference";

const ClipsGrid = ({ clips }) => {
  return (
    <div className="clipsContainer">
      <div className="clipsGrid">
        {clips.map((clip, i) => (
          <div key={clip.id} className="clipCard">
            <div className="clipOverlay">
              <img
                className="clipImage"
                src={clip.thumbnailUrl}
                alt={clip.description}
              />
              <div className="clipDescriptionOverlay">
                <div className="clipDescription">{clip.description}</div>
                <div className="clipDescription">
                  Likes: {clip.likeCount} Shares: {clip.shareCount}
                </div>
                <div className="clipDescription">
                  {clip.categories.map((category, i) => (
                    <span key={i}>{category}, </span>
                  ))}
                </div>
                <div className="clipDescription">
                  <span>{formatAsDate(clip.creationTime)}</span> -
                  <span>{getTimeDifference(clip.creationTime)}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClipsGrid;
