const InputField = ({ label, value, setValue, placeholder }) => (
  <div className="inputRow">
    <label className="inputLabel">{label}</label>
    <input
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className="inputField"
      placeholder={placeholder}
    />
  </div>
);

export default InputField;
