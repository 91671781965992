import React, { useState } from "react";
import FormSection from "./FormSection";

const BaseSearchForm = ({
  formData,
  onSubmit,
  enableForYouFollowingSwitcher,
  isFollowing,
  setIsFollowing,
  fieldConfig,
  initialValues = {}
}) => {
  const [formState, setFormState] = useState({
    favoriteTeam: initialValues.favoriteTeam || '',
    followedTeams: initialValues.followedTeams || '',
    followedPlayers: initialValues.followedPlayers || '',
    creationTimeBoostFactor: initialValues.creationTimeBoostFactor || ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formState);
  };

  const getFilteredFields = (section, fields) => {
    return fields.filter((field) => fieldConfig[field.label]);
  };

  return (
    <div className="formContainer">
      <form onSubmit={handleSubmit} className="form">
        <button type="submit" className="button">
          Search
        </button>

        {enableForYouFollowingSwitcher && (
          <div className="toggleButtonContainer">
            <button
              className={`toggleButton ${!isFollowing ? "active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setIsFollowing(false);
              }}
            >
              For You
            </button>
            <button
              className={`toggleButton ${isFollowing ? "active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setIsFollowing(true);
              }}
            >
              Following
            </button>
          </div>
        )}

        <div className="inputTable">
          <FormSection
            title="General"
            fields={getFilteredFields("General", [
              {
                label: "API URL",
                value: formData.apiUrl,
                setValue: formData.setApiUrl,
              },
              {
                label: "API Key",
                value: formData.apiKey,
                setValue: formData.setApiKey,
              },
              {
                label: "Client Platform",
                value: formData.clientPlatform,
                setValue: formData.setClientPlatform,
              },
              {
                label: "Client Version",
                value: formData.clientVersion,
                setValue: formData.setClientVersion,
              },
              {
                label: "Collection ID",
                value: formData.collectionId,
                setValue: formData.setCollectionId,
              },
            ])}
          />

          <FormSection
            title="Paging"
            fields={getFilteredFields("Paging", [
              {
                label: "Enable Paging",
                value: formData.isPagingEnabled,
                setValue: formData.setIsPagingEnabled
              },
              {
                label: "Page Number",
                value: formData.pageNumber,
                setValue: formData.setPageNumber
              },
              {
                label: "Category",
                value: formData.category,
                setValue: formData.setCategory
              }
            ])} 
          />

          <FormSection
            title="Personalization"
            fields={getFilteredFields("Personalization", [
              {
                label: "Favorite Team",
                value: formState.favoriteTeam,
                setValue: (value) => setFormState(prev => ({ ...prev, favoriteTeam: value })),
              },
              {
                label: "Followed Teams",
                value: formState.followedTeams,
                setValue: (value) => setFormState(prev => ({ ...prev, followedTeams: value })),
              },
              {
                label: "Followed Players",
                value: formState.followedPlayers,
                setValue: (value) => setFormState(prev => ({ ...prev, followedPlayers: value })),
              },
            ])}
          />

          <FormSection
            title="Boost Factors"
            fields={getFilteredFields("Boost Factors", [
              {
                label: "Recency Boost Factor",
                value: formState.creationTimeBoostFactor,
                setValue: (value) => setFormState(prev => ({ ...prev, creationTimeBoostFactor: value })),
              },
              {
                label: "Like Count Boost Factor",
                value: formData.likeCountBoostFactor,
                setValue: formData.setLikeCountBoostFactor,
              },
              {
                label: "Share Count Boost Factor",
                value: formData.shareCountBoostFactor,
                setValue: formData.setShareCountBoostFactor,
              },
              {
                label: "Primary Category Boost Factor",
                value: formData.primaryCategoryBoostFactor,
                setValue: formData.setPrimaryCategoryBoostFactor,
              },
              {
                label: "Secondary Category Boost Factor",
                value: formData.secondaryCategoryBoostFactor,
                setValue: formData.setSecondaryCategoryBoostFactor,
              },
              {
                label: "Clip Creation Time Lower Bound",
                value: formData.clipCreationTimeLowerBound,
                setValue: formData.setClipCreationTimeLowerBound,
                placeholder: "E.g. 2023-04-17T00:00:00-00:00",
              },
              {
                label: "Custom Boost Factor",
                value: formData.customBoostFactor,
                setValue: formData.setCustomBoostFactor,
              },
              {
                label: "Custom Boost Category External IDs",
                value: formData.customBoostCategoryExternalIds,
                setValue: formData.setCustomBoostCategoryExternalIds,
              },
              {
                label: "Categories To Suppress Boost Factor",
                value: formData.categoriesToSuppressBoostFactor,
                setValue: formData.setCategoriesToSuppressBoostFactor,
              },
              {
                label: "Categories To Suppress External IDs",
                value: formData.categoriesToSuppressExternalIds,
                setValue: formData.setCategoriesToSuppressExternalIds,
              },
            ])}
          />
        </div>
      </form>
    </div>
  );
};

export default BaseSearchForm;
