import { useState } from 'react';

const useClipsData = (initialValues = {}) => {
  const [clipsData, setClipsData] = useState([]);
  const [isFollowing, setIsFollowing] = useState(initialValues.isFollowing || false);

  return {
    clipsData,
    setClipsData,
    isFollowing,
    setIsFollowing
  };
}; 

export default useClipsData;