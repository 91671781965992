import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import StoriesGrid from "../components/StoriesGrid";
import buildApiUrl from "../utils/urlBuilder";
import useApiConfig from "../hooks/useApiConfig";
import { DEFAULT_API_CONFIG } from "../constants/config";
import StoriesSearchForm from "../components/StoriesSearchForm";

const noop = () => {};

const StoriesContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const apiConfig = useApiConfig({
    apiUrl: DEFAULT_API_CONFIG.API_URL,
    apiKey: DEFAULT_API_CONFIG.API_KEY,
    clientPlatform: DEFAULT_API_CONFIG.CLIENT_PLATFORM,
    clientVersion: DEFAULT_API_CONFIG.CLIENT_VERSION
  });

  const [storiesData, setStoriesData] = useState([]);

  const baseUrl = `https://${apiConfig.apiUrl}/api/app/story/stories/following?x-storyteller-api-key=${apiConfig.apiKey}&ClientPlatform=${apiConfig.clientPlatform}&ClientVersion=${apiConfig.clientVersion}`

  const fetchStories = useCallback(async (formState) => {
    const userAttributesString = (() => {
      const params = [];
      if (formState.favoriteTeam) params.push(`userAttributes[favoriteTeam]=${formState.favoriteTeam}`);
      if (formState.followedTeams) params.push(`userAttributes[followedTeams]=${formState.followedTeams}`);
      if (formState.followedPlayers) params.push(`userAttributes[followedPlayers]=${formState.followedPlayers}`);
      return params.length ? `&${params.join('&')}` : '';
    })();

    const publicApiUrl = buildApiUrl({
      baseUrl,
      userAttributes: userAttributesString,
      boostConfig: formState.creationTimeBoostFactor ? `&boostConfig[creationTimeBoostFactor]=${formState.creationTimeBoostFactor}` : ''
    });

    try {
      const response = await fetch(publicApiUrl);
      const data = await response.json();
      setStoriesData(data.stories);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [baseUrl]);

  const handleSearch = useCallback((formState) => {
    const newParams = {};
    if (formState.favoriteTeam) newParams['userAttributes[favoriteTeam]'] = formState.favoriteTeam;
    if (formState.followedTeams) newParams['userAttributes[followedTeams]'] = formState.followedTeams;
    if (formState.followedPlayers) newParams['userAttributes[followedPlayers]'] = formState.followedPlayers;
    if (formState.creationTimeBoostFactor) newParams['boostConfig[creationTimeBoostFactor]'] = formState.creationTimeBoostFactor;

    setSearchParams(newParams);
    fetchStories(formState);
  }, [setSearchParams, fetchStories]);

  // Handle initial load and browser navigation
  useEffect(() => {
    if (searchParams.toString()) {
      const currentState = {
        favoriteTeam: searchParams.get('userAttributes[favoriteTeam]'),
        followedTeams: searchParams.get('userAttributes[followedTeams]'),
        followedPlayers: searchParams.get('userAttributes[followedPlayers]'),
        creationTimeBoostFactor: searchParams.get('boostConfig[creationTimeBoostFactor]')
      };
      fetchStories(currentState);
    } else {
      fetchStories({});
    }
  }, [searchParams, fetchStories]);

  return (
    <div className="container">
      <StoriesSearchForm 
        formData={{...apiConfig}}
        onSubmit={handleSearch}
        enableForYouFollowingSwitcher={false}
        isFollowing={false}
        setIsFollowing={noop}
        initialValues={{
          favoriteTeam: searchParams.get('userAttributes[favoriteTeam]'),
          followedTeams: searchParams.get('userAttributes[followedTeams]'),
          followedPlayers: searchParams.get('userAttributes[followedPlayers]'),
          creationTimeBoostFactor: searchParams.get('boostConfig[creationTimeBoostFactor]')
        }}
      />
      <StoriesGrid stories={storiesData} />
    </div>
  );
};

export default StoriesContent;