import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import ClipsGrid from "../components/ClipsGrid";
import ClipsSearchForm from "../components/ClipsSearchForm";
import buildApiUrl from "../utils/urlBuilder";
import useApiConfig from "../hooks/useApiConfig";
import useUserAttributes from "../hooks/useUserAttributes";
import useBoostConfig from "../hooks/useBoostConfig";
import useClipsData from "../hooks/useClipsData";
import usePagingConfig from "../hooks/usePagingConfig";
import { DEFAULT_API_CONFIG } from "../constants/config";

const ClipsContent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const apiConfig = useApiConfig({
      apiUrl: DEFAULT_API_CONFIG.API_URL,
      apiKey: DEFAULT_API_CONFIG.API_KEY,
      clientPlatform: DEFAULT_API_CONFIG.CLIENT_PLATFORM,
      clientVersion: DEFAULT_API_CONFIG.CLIENT_VERSION,
      collectionId: DEFAULT_API_CONFIG.COLLECTION_ID
    });
    const userAttributes = useUserAttributes({
      favoriteTeam: searchParams.get('favoriteTeam'),
      followedTeams: searchParams.get('followedTeams'),
      followedPlayers: searchParams.get('followedPlayers')
    });
    const boostConfig = useBoostConfig({
      creationTimeBoostFactor: searchParams.get('creationTimeBoostFactor'),
      likeCountBoostFactor: searchParams.get('likeCountBoostFactor'),
      shareCountBoostFactor: searchParams.get('shareCountBoostFactor'),
      primaryCategoryBoostFactor: searchParams.get('primaryCategoryBoostFactor'),
      secondaryCategoryBoostFactor: searchParams.get('secondaryCategoryBoostFactor'),
      clipCreationTimeLowerBound: searchParams.get('clipCreationTimeLowerBound')
    });
    const pagingConfig = usePagingConfig({
      isPagingEnabled: searchParams.get('isPagingEnabled'),
      pageNumber: searchParams.get('pageNumber'),
      category: searchParams.get('category'),
    })
    const clipsData = useClipsData({
      isFollowing: searchParams.get('isFollowing') === 'true'
    });
  
    var urlBuilder = [`https://${apiConfig.apiUrl}/api/app/clips/${apiConfig.collectionId}/clips`];    
    if (pagingConfig.isPagingEnabled === 'true') {
      urlBuilder.push('/paged');
    }
    if (clipsData.isFollowing) {
      urlBuilder.push('/following');
    }
    urlBuilder.push(`?x-storyteller-api-key=${apiConfig.apiKey}&ClientPlatform=${apiConfig.clientPlatform}&ClientVersion=${apiConfig.clientVersion}`);

    const baseUrl = urlBuilder.join('');

    const handleSearch = useCallback(async (formState) => {
      setSearchParams({
        ...(clipsData.isFollowing ? { isFollowing: 'true' } : {}),
        ...formState.favoriteTeam && { 'userAttributes[favoriteTeam]': formState.favoriteTeam },
        ...formState.followedTeams && { 'userAttributes[followedTeams]': formState.followedTeams },
        ...formState.followedPlayers && { 'userAttributes[followedPlayers]': formState.followedPlayers },
        ...boostConfig.creationTimeBoostFactor && { creationTimeBoostFactor: boostConfig.creationTimeBoostFactor },
        ...boostConfig.likeCountBoostFactor && { likeCountBoostFactor: boostConfig.likeCountBoostFactor },
        ...boostConfig.shareCountBoostFactor && { shareCountBoostFactor: boostConfig.shareCountBoostFactor },
        ...boostConfig.primaryCategoryBoostFactor && { primaryCategoryBoostFactor: boostConfig.primaryCategoryBoostFactor },
        ...boostConfig.secondaryCategoryBoostFactor && { secondaryCategoryBoostFactor: boostConfig.secondaryCategoryBoostFactor },
        ...boostConfig.clipCreationTimeLowerBound && { clipCreationTimeLowerBound: boostConfig.clipCreationTimeLowerBound },
        ...pagingConfig.isPagingEnabled && { isPagingEnabled: pagingConfig.isPagingEnabled },
        ...pagingConfig.pageNumber && { pageNumber: pagingConfig.pageNumber },
        ...pagingConfig.category && { category: pagingConfig.category }
      });

      const userAttributesString = (() => {
        const params = [];
        if (formState.favoriteTeam) params.push(`userAttributes[favoriteTeam]=${formState.favoriteTeam}`);
        if (formState.followedTeams) params.push(`userAttributes[followedTeams]=${formState.followedTeams}`);
        if (formState.followedPlayers) params.push(`userAttributes[followedPlayers]=${formState.followedPlayers}`);
        return params.length ? `&${params.join('&')}` : '';
      })();

      const publicApiUrl = buildApiUrl({
        baseUrl,
        userAttributes: userAttributesString,
        boostConfig: boostConfig.getBoostConfigString(),
        pagingConfig: pagingConfig.getPagingConfigString()
      });
  
      try {
        const response = await fetch(publicApiUrl);
        const data = await response.json();
        clipsData.setClipsData(data.clips);
      } catch (error) {
        console.error("Error fetching data:", error);
        }
      },
      [baseUrl, boostConfig, pagingConfig, clipsData, setSearchParams]
    );
  
    return (
      <div className="container">
        <ClipsSearchForm 
          formData={{...apiConfig,...userAttributes, ...boostConfig, ...pagingConfig}}
          onSubmit={handleSearch}
          enableForYouFollowingSwitcher={true}
          isFollowing={clipsData.isFollowing}
          setIsFollowing={clipsData.setIsFollowing}
        />
        <ClipsGrid clips={clipsData.clipsData} />
      </div>
    );
};

export default ClipsContent; 