import { useState } from "react";

const usePagingConfig = (initialValues = {}) => {
  const [isPagingEnabled, setIsPagingEnabled] = useState(initialValues.isPagingEnabled || 'false');
  const [pageNumber, setPageNumber] = useState(initialValues.pageNumber || '0');
  const [category, setCategory] = useState(initialValues.category || '');

  const getPagingConfigString = () => {
    var path = `&pageNumber=${pageNumber}`
    if (category) {
      path += `&categoryId=${category}`;
    }
    return path;
  };

  return {
    isPagingEnabled,
    setIsPagingEnabled,

    pageNumber,
    setPageNumber,    
    
    category,
    setCategory,

    getPagingConfigString
  };
}; 

export default usePagingConfig;