import moment from "moment";

export const getTimeDifference = (creationTime) => {
    const creationDate = moment.utc(creationTime);
    const creationTimeBoostCenterDate = moment.utc(moment.now());
    const duration = moment.duration(
      creationDate.diff(creationTimeBoostCenterDate)
    );
  
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
  
    return `${days}d ${hours}h ${minutes}m`;
  };