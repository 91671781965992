const buildApiUrl = ({
  baseUrl,
  userAttributes,
  boostConfig,
  pagingConfig
}) => {
  const params = [];
  if (userAttributes) params.push(userAttributes);
  if (boostConfig) params.push(boostConfig);
  if (pagingConfig) params.push(pagingConfig);

  return `${baseUrl}${params.join('')}`;
}; 

export default buildApiUrl;