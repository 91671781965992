import React from "react";
import BaseSearchForm from "./BaseSearchForm";

const ClipsSearchForm = (props) => {
  const fieldConfig = {
    "API URL": true,
    "API Key": true,
    "Client Platform": true,
    "Client Version": true,
    "Collection ID": true,
    "Favorite Team": true,
    "Followed Teams": true,
    "Followed Players": true,
    "Recency Boost Factor": true,
    "Like Count Boost Factor": true,
    "Share Count Boost Factor": true,
    "Primary Category Boost Factor": true,
    "Secondary Category Boost Factor": true,
    "Clip Creation Time Lower Bound": true,
    "Enable Paging": true,
    "Page Number": true,
    "Category": true
  };

  return <BaseSearchForm {...props} fieldConfig={fieldConfig} />;
};

export default ClipsSearchForm; 