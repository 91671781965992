import { useState } from "react";

const useUserAttributes = (initialValues = {}) => {
  const [favoriteTeam, setFavoriteTeam] = useState(initialValues.favoriteTeam);
  const [followedTeams, setFollowedTeams] = useState(initialValues.followedTeams);
  const [followedPlayers, setFollowedPlayers] = useState(initialValues.followedPlayers);

  const getUserAttributesString = () => {
    const params = [];
    if (favoriteTeam) params.push(`userAttributes[favoriteTeam]=${favoriteTeam}`);
    if (followedTeams) params.push(`userAttributes[followedTeams]=${followedTeams}`);
    if (followedPlayers) params.push(`userAttributes[followedPlayers]=${followedPlayers}`);
    
    return params.length ? `&${params.join('&')}` : '';
  };

  return {
    favoriteTeam,
    setFavoriteTeam,
    followedTeams,
    setFollowedTeams,
    followedPlayers,
    setFollowedPlayers,
    getUserAttributesString
  };
}; 

export default useUserAttributes;