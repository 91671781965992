import { useState } from "react";

const useApiConfig = (initialValues = {}) => {
  const [apiUrl, setApiUrl] = useState(initialValues.apiUrl || "api.usestoryteller.com");
  const [apiKey, setApiKey] = useState(initialValues.apiKey || "4ed98ae9-4e80-4849-b727-70a1b68dccef");
  const [clientPlatform, setClientPlatform] = useState(initialValues.clientPlatform || "iOS");
  const [clientVersion, setClientVersion] = useState(initialValues.clientVersion || "9.7.0");
  const [collectionId, setCollectionId] = useState(initialValues.collectionId || "opening-week-fire-moments");

  return {
    apiUrl,
    setApiUrl,
    apiKey,
    setApiKey,
    clientPlatform,
    setClientPlatform,
    clientVersion,
    setClientVersion,
    collectionId,
    setCollectionId
  };
};

export default useApiConfig;