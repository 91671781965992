import React from "react";
import BaseSearchForm from "./BaseSearchForm";

const StoriesSearchForm = ({ initialValues, ...props }) => {
  const fieldConfig = {
    "API URL": true,
    "API Key": true,
    "Client Platform": true,
    "Client Version": true,
    "Collection ID": false,
    "Favorite Team": true,
    "Followed Teams": true,
    "Followed Players": true,
    "Recency Boost Factor": true,
    "Like Count Boost Factor": false,
    "Share Count Boost Factor": false,
    "Primary Category Boost Factor": false,
    "Secondary Category Boost Factor": false,
    "Clip Creation Time Lower Bound": false,
    "Custom Boost Factor": false,
    "Custom Boost Category External IDs": false,
    "Categories To Suppress External IDs": false,
    "Categories To Suppress Boost Factor": false,
  };

  return <BaseSearchForm {...props} fieldConfig={fieldConfig} initialValues={initialValues} />;
};

export default StoriesSearchForm; 